@import '../../../assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.InfoCircleTwoTone {
  font-size: 14px;
}

.CreditTypeRadio {
  height: 100%;
  width: 168px;

  @media only screen and (max-width: $sm) {
    line-height: 20px;
  }
}

.InputLabel {
  @extend .BottomMarginL;
}

.InputContainer {
  @extend .BottomMarginXXL;
}

.PaymentsNumberOption {
  background: white;
  border: 1px solid $border-gray;
  border-radius: 2px;
  color: $font-dark;
  font-size: 14px;
  height: 40px;
  padding: 8px;
  text-align: center;
}

.PaymentsNumberOptionActive {
  background: $pale-primary;
  border: 1px solid $primary;
}

.PaymentInputContainer,
%payment-input-container {
  @extend .BottomMarginL;
  position: relative;
}

.LoadingPaymentInputContainer {
  @extend %payment-input-container;
  opacity: 50%;
  pointer-events: none;
}

.Spin {
  color: #8c8c8c;
  font-size: 48px;
  left: calc(50% - 24px);
  position: absolute;
  top: calc(50% - 24px);
  z-index: 2;
}

.PaymentInputPorcentage {
  color: $font-secondary;
  position: absolute;
  right: 26px;
  top: 9px;
}

.Slider {
  padding-bottom: 52px;
}

.InsurancePrice {
  font-weight: 500;
  margin-bottom: 0;
}

.InsuranceName {
  margin-bottom: 4px;
}

.RadioButton,
%radiobutton {
  background: white;
  border: 1px solid $border-gray;
  border-radius: 5px;
  color: $font-dark;
  cursor: pointer;
  margin-bottom: 24px;
  padding: 8px 12px 12px;
}

.ActiveRadioButton {
  background: $pale-primary;
  border: 1px solid $primary;
}

.Circle {
  border: 1px solid $border-gray;
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  width: 22px;
}

.ActiveCircle {
  background: $primary;
  border: 1px solid $primary;
}

.ActiveIcon {
  left: 3px;
  position: relative;
  top: -2px;
}

.DisabledCircle {
  background: $border-gray;
  border-color: $border-gray;
}

.RequiredInsurance {
  background: $background-gray;
  border-color: $border-gray;
  cursor: not-allowed;
  margin-bottom: 0;
}

.InfoButton {
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  margin-left: 12px;
}

.InsuranceContainer {
  @extend .BottomMarginL;
}

.RadioText {
  text-align: center;
  width: calc(100% - 22px);
}

.FullWidth {
  width: 100%;
}
