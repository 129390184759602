.FormInput {
  margin-bottom: 0;
}

.Popover {
  align-self: center;
  max-width: 35%;
  text-align: justify;
  text-justify: inter-word;
}

.InfoCircleTwoTone {
  color: gray;
  font-size: 14px;
  margin-left: 8px;
}
