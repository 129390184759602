@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ModalTitle {
  @extend .BottomMarginXXL;
}

.ModalText h2 {
  @extend .BottomMarginL;
}

.LastModalParagraph,
%lastmodalparagraph {
  color: #272638;
  text-align: justify;
}

.ModalParagraph {
  @extend %lastmodalparagraph;
  @extend .BottomMarginXL;
  color: #272638;
  text-align: justify;
}
