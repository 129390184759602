/* stylelint-disable selector-class-pattern */
@import '../spacing-variables';

///

.Margin0 {
  margin: 0;
}

.XMargin0 {
  margin-left: 0;
  margin-right: 0;
}

.YMargin0 {
  margin-bottom: 0;
  margin-top: 0;
}

.TopMargin0 {
  margin-top: 0;
}

.RightMargin0 {
  margin-right: 0;
}

.BottomMargin0 {
  margin-bottom: 0;
}

.LeftMargin0 {
  margin-left: 0;
}

///

.MarginXXL {
  margin: $spacing-xxl;
}

.XMarginXXL {
  margin-left: $spacing-xxl;
  margin-right: $spacing-xxl;
}

.YMarginXXL {
  margin-bottom: $spacing-xxl;
  margin-top: $spacing-xxl;
}

.TopMarginXXL {
  margin-top: $spacing-xxl;
}

.RightMarginXXL {
  margin-right: $spacing-xxl;
}

.BottomMarginXXL {
  margin-bottom: $spacing-xxl;
}

.LeftMarginXXL {
  margin-left: $spacing-xxl;
}

///

.MarginXL {
  margin: $spacing-xl;
}

.XMarginXL {
  margin-left: $spacing-xl;
  margin-right: $spacing-xl;
}

.YMarginXL {
  margin-bottom: $spacing-xl;
  margin-top: $spacing-xl;
}

.TopMarginXL {
  margin-top: $spacing-xl;
}

.RightMarginXL {
  margin-right: $spacing-xl;
}

.BottomMarginXL {
  margin-bottom: $spacing-xl;
}

.LeftMarginXL {
  margin-left: $spacing-xl;
}

///

.MarginL {
  margin: $spacing-l;
}

.XMarginL {
  margin-left: $spacing-l;
  margin-right: $spacing-l;
}

.YMarginL {
  margin-bottom: $spacing-l;
  margin-top: $spacing-l;
}

.TopMarginL {
  margin-top: $spacing-l;
}

.RightMarginL {
  margin-right: $spacing-l;
}

.BottomMarginL {
  margin-bottom: $spacing-l;
}

.LeftMarginL {
  margin-left: $spacing-l;
}

///

.MarginM {
  margin: $spacing-m;
}

.XMarginM {
  margin-left: $spacing-m;
  margin-right: $spacing-m;
}

.YMarginM {
  margin-bottom: $spacing-m;
  margin-top: $spacing-m;
}

.TopMarginM {
  margin-top: $spacing-m;
}

.RightMarginM {
  margin-right: $spacing-m;
}

.BottomMarginM {
  margin-bottom: $spacing-m;
}

.LeftMarginM {
  margin-left: $spacing-m;
}

///

.MarginS {
  margin: $spacing-s;
}

.XMarginS {
  margin-left: $spacing-s;
  margin-right: $spacing-s;
}

.YMarginS {
  margin-bottom: $spacing-s;
  margin-top: $spacing-s;
}

.TopMarginS {
  margin-top: $spacing-s;
}

.RightMarginS {
  margin-right: $spacing-s;
}

.BottomMarginS {
  margin-bottom: $spacing-s;
}

.LeftMarginS {
  margin-left: $spacing-s;
}

///

.MarginXS {
  margin: $spacing-xs;
}

.XMarginXS {
  margin-left: $spacing-xs;
  margin-right: $spacing-xs;
}

.YMarginXS {
  margin-bottom: $spacing-xs;
  margin-top: $spacing-xs;
}

.TopMarginXS {
  margin-top: $spacing-xs;
}

.RightMarginXS {
  margin-right: $spacing-xs;
}

.BottomMarginXS {
  margin-bottom: $spacing-xs;
}

.LeftMarginXS {
  margin-left: $spacing-xs;
}
