@import 'assets/styles/variables';

body {
  --spacing-xxl: 36px;
  --spacing-xl: 24px;
  --spacing-l: 16px;
  --spacing-m: 12px;
  --spacing-s: 8px;
  --spacing-xs: 4px;

  @media only screen and (max-width: $xl) {
    --spacing-xxl: 24px;
    --spacing-xl: 16px;
    --spacing-l: 12px;
  }

  @media only screen and (max-width: $lg) {
    --spacing-xxl: 36px;
    --spacing-xl: 24px;
    --spacing-l: 16px;
  }

  @media only screen and (max-width: $md) {
    --spacing-xxl: 24px;
    --spacing-xl: 16px;
    --spacing-l: 12px;
  }
}

$spacing-xxl: var(--spacing-xxl);
$spacing-xl: var(--spacing-xl);
$spacing-l: var(--spacing-l);
$spacing-m: var(--spacing-m);
$spacing-s: var(--spacing-s);
$spacing-xs: var(--spacing-xs);
