@import 'assets/styles/variables';

.Container {
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;

  @media only screen and (max-width: $xl) {
    height: 54px;
  }

  @media only screen and (max-width: $lg) {
    height: 64px;
  }

  @media only screen and (max-width: $md) {
    height: 54px;
  }
}

.Logo {
  height: 32px;
  width: auto;

  @media only screen and (max-width: $xl) {
    height: 22px;
  }

  @media only screen and (max-width: $lg) {
    height: 32px;
  }

  @media only screen and (max-width: $md) {
    height: 22px;
  }
}

.NavItems {
  min-width: 0;

  .NavItems {
    width: 100%;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
  }
}

.NavBarItems {
  width: fit-content;
}

.LogInButton {
  color: $primary;
  font-size: 16px;
  margin-right: 24px;
  
  &:hover {
    color: $dark-primary;
  }
}

.UserContainer {
  font-size: 16px;
  height: 100%;
  margin: auto 36px auto 0;
  width: 100%;
}

.UserLogoContainer {
  align-items: center;
  background: $pale-primary;
  border-radius: 50%;
  float: right;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}

.Initials {
  bottom: 10px;
  color: $primary;
  font-size: 18px;
  line-height: 18px;
  position: relative;
}
