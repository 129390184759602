@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.TitleText {
  color: #8c8c8c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.CurrentTitleText {
  color: $font-primary;
  font-size: 14px;
  font-weight: 400;
}

.PanelContent {
  min-height: calc(20vh + 40px);
  width: 100%;
}

.Price {
  line-height: 30px;
  margin-bottom: 0;
}

.PanelRowContainer {
  background: $pale-primary;
  border: solid 1px $primary;
  border-radius: 5px;
  padding: 16px;
}

.Spin,
%spin {
  color: #8c8c8c;
}

.SpinLarge {
  @extend %spin;

  font-size: 26px;
}

.SpinContainer {
  padding-top: 60px;
}

.InputLabel {
  @extend .BottomMarginL;
}

.InputContainer {
  @extend .BottomMarginXXL;
}

.InfoCircleTwoTone {
  font-size: 14px;
}

.CreditTypeRadio {
  height: 100%;
  width: 168px;

  @media only screen and (max-width: $sm) {
    line-height: 20px;
  }
}

.FullHeight {
  height: 100%;
}

.SummaryRow {
  width: 100%;
}

.SummaryTitle {
  margin-bottom: 12px;
}

.SummarySubtitle {
  color: $font-secondary;
  margin-bottom: 12px;
}

.CreditDetailsButton {
  font-weight: 400;
  height: 18px;
}

.InfoButton {
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  margin-left: 12px;
}

.InfoSubtitle {
  color: $font-secondary;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
}

.RadioButton,
%radiobutton {
  @extend .PaddingM;
  background: white;
  border: 1px solid $border-gray;
  border-radius: 5px;
  color: $font-dark;
  cursor: pointer;
  width: 100%;
}

.ActiveRadioButton {
  background: $pale-primary;
  border: 1px solid $primary;
}

.RadioCircleContainer {
  position: absolute;
  right: 20px;
  top: 12px;
}

.RadioText {
  text-align: center;
  width: calc(100% - 22px);

  @media only screen and (max-width: $md) {
    padding-left: 12px;
    text-align: start;
  }
}

.ProductName {
  @extend .BottomMarginL;
  font-size: 14px;
  font-weight: 400;
}

.ProductDescription {
  @extend .TopMarginXS;
  font-size: 12px;
  font-weight: 400;
}

.Circle {
  border: 1px solid $border-gray;
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  width: 22px;
}

.ActiveCircle {
  background: $primary;
  border: 1px solid $primary;
}

.ActiveIcon {
  left: 3px;
  position: relative;
  top: -2px;
}

.DisabledCircle {
  background: white;
}

.NoMargin {
  margin: 0;
}
