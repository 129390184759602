@import 'assets/styles/UtilityClasses/index';
@import 'assets/styles/variables';

.FormInput {
  margin-bottom: 0;
}

.Input {
  height: 40px;
}

.FinancingSwitch {
  display: flex;
  flex-direction: column;
  
  p {
    margin-bottom: 0;
  }
}

.LoadingModal {
  background: #fafafa;
  border: 0;
  border-radius: 2px;
  padding: 8px 16px;
  width: 100%;
}

.SuccessIcon {
  color: gray;
}

.AlertIcon {
  margin-right: 8px;
}
