.AnimationContainer {
  --animation-size: 200px;

  height: 100%;
  margin-top: calc((100vh - var(--animation-size)) / 2);

  .Animation {
    height: var(--animation-size);
    width: var(--animation-size);
  }
}
