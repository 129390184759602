.TableContainer {
  .ActionsContainer {
    max-width: 150px;
  }

  .ActionButton {
    margin: 2px;
    min-width: 100px;
    width: 100%;
  }

  .InfoContainer {
    width: 100%;
  }
}

.UserActionButton {
  background-color: white;
  border: 0;
  border-radius: 5px;
  margin: 0;
  padding-bottom: 6px;
  text-align: left;
  width: 90px;
}

.ActionText {
  color: #272638;
  margin: 0;
  padding: 0;
  width: 100px;
}

.Span {
  color: #272638;
}

.Overlay {
  border-radius: 5px;
  height: 68px;
  width: 100px;

  div {
    border-radius: 5px;
  }
}
