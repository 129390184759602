@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ComponentContainer {
  @extend .FullWidth;
  border-radius: 5px;
}

.BackArrow {
  margin-bottom: 4px;
  margin-left: -8px;
  margin-right: 4px;
}

span {
  overflow: initial !important;
}
