@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ModalTitle {
  @extend .BottomMarginXXL;
}

.ModalText h2 {
  @extend .BottomMarginL;
}

.ModalParagraph {
  @extend .BottomMarginXL;
  color: #272638;
  text-align: justify;
}

.LastModalText {
  @extend .BottomMarginL, .TopMarginL;
}

.ModalInternalParagraph {
  @extend .BottomMarginL;
  color: #272638;
  text-align: justify;
}


.ModalSubtitle {
  @extend .BottomMarginL;
}

.ModalSubtitleWithMargin {
  @extend .YMarginL;
}

.List {
  padding-left: 20px;

  li p {
    color: #272638;
    margin-bottom: 4px;
  }
}
