@import 'assets/styles/spacing-variables';
@import 'assets/styles/UtilityClasses/index';
@import 'assets/styles/variables';

.Container {
  @extend .FullScreen;
}

.LayoutContainer {
  @extend .FullHeight, .WhiteBackground;
}

.TopBarContainer {
  margin-bottom: $topbar-height;
}

.ContainerMainRow {
  @extend .FullWidth, .PaddingXXL;
}

.SpinLarge {
  color: $gray-text;
  font-size: 26px;
}

.MainColumn {
  @extend .FullWidth;
  padding-right: calc(#{$spacing-xxl} - #{$scroll-bar-width});

  @media only screen and (max-width: $lg) {
    padding-right: 0;
  }
}

.Footer { 
  @extend .BottomPaddingL, .RightPaddingXXL;
  bottom: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
