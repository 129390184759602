// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

// COLORS
// stylelint-disable-next-line
body {
  --primary: #4628e6;
  --pale-primary: #f5f0ff;
  --dark-primary: #2b17bf;
  --primary-focus-outline: #2b17bf50;

  --top-bar-height: 64px;

  @media only screen and (max-width: $xl) {
    --top-bar-height: 54px;
  }

  @media only screen and (max-width: $lg) {
    --top-bar-height: 64px;
  }

  @media only screen and (max-width: $md) {
    --top-bar-height: 54px;
  }
}

$primary: var(--primary);
$primary-opacity: var(--primary-opacity);
$pale-primary: var(--pale-primary);
$dark-primary: var(--dark-primary);
$primary-focus-outline: var(--primary-focus-outline); // focus buttons
$background-darker-gray: #fafafa;
$background: #f9f9fb;

$dark-text: #272638;
$gray-text: #8c8c8c; // gray-7
$gray-6: #bfbfbf; // steps
$border-gray: #d9d9d9;
$background-gray: whitesmoke; // #f5f5f5
$gray-2: #fafafa;

// FONT COLORS
$font-primary: $primary;
$font-dark: $dark-text;
$font-secondary: $gray-text;

// ALERTS
$error-red: #ff4d4f;
$warning-yellow: #ffc53d;
$warning-backgorund: #fffbe6;
$succcess-green: #52c41a;
$info-blue: #1890ff;

// LAYOUT SIZES
$topbar-height: var(--top-bar-height);
$footer-height: 20px;
$image-aspect-ratio: calc(1920 / 1080);
$max-image-height: calc((25vw - 48px) / #{$image-aspect-ratio});
$scroll-bar-width: 8px;
