/* stylelint-disable property-no-vendor-prefix */
@import 'assets/styles/variables';
@import 'assets/styles/spacing-variables';

.Container {
  height: calc(100vh - $topbar-height - 2 * $spacing-xxl);
}

.Background {
  background: $primary;
  height: 100vh;
  -webkit-mask: url('/assets/images/Background.svg');
  mask: url('/assets/images/Background.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: cover;
  position: absolute;
  right: calc($spacing-xxl * -1);
  top: calc(-1 * $spacing-xxl);
  width: 60vw;
}

.CenteredText {
  font-size: 45px;
  line-height: 55px;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    font-size: 35px;
  }
}

.SignUp {
  background-color: white;
  border-color: #e2e8f0;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 10px 15px -3px RGBA(0 0 0 / 10%);
  padding: 48px 36px;
}

.Title {
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}

.FormItem {
  font-size: 16px;
  font-weight: 500;
  // height: 60px;
}

.FullSize {
  height: 100%;
  width: 100%;
}

.FullHeight {
  height: 100%;
}

.ButtonContainer {
  margin-top: 24px;
  width: 100%;
}

.MarginBottom {
  margin-bottom: 16px;
}

.MarginTop {
  margin-top: 16px;
}
