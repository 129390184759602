@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ModalTitle {
  @extend .BottomMarginXXL;
}

.CreditInfo {
  color: $font-dark;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.Separator {
  @extend .YMarginL;
}

.CreditInfoValue {
  color: $font-dark;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.ExtraDescription {
  color: rgb(126 126 126);
  font-family: Roboto, sans-serif;
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

.InfoCircleTwoTone {
  color: gray;
  font-size: 14px;
  margin-left: 8px;
}

.Popover {
  max-width: 35%;
  text-align: justify;
  text-justify: inter-word;
}

.ModalSubtitle {
  @extend .BottomMarginL;
}
