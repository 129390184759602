@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ModalTitle {
  @extend .BottomMarginXXL;
}

.ModalText h2 {
  @extend .BottomMarginL;
}

.ModalParagraph,
%modalparagraph {
  color: #272638;
  margin-bottom: 24px;
  text-align: justify;
}

ul {
  @media only screen and (max-width: $sm) {
    padding-left: 20px;
  }
}

.LastModalParagraph {
  @extend %modalparagraph;
  margin-bottom: 0;
}
