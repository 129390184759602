.TableContainer {
  margin-top: 36px;
  width: 100%;
}

.BackButton {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}

.ConcessionaireCard {
  border: solid 1px #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  margin-top: 2rem;
  padding: 1rem;
}

.UsersContainer {
  .Divider {
    border: solid 1px #e2e8f0;
  }

  .UserCard {
    border: solid 1px #e2e8f0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    margin-top: 2rem;
    padding: 1rem;
  }
}

.UserName {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.InviteUser {
  border-radius: 5px;
  color: white;
  font-size: 16px;
  height: 40px;
  width: 135px;
}
