/* stylelint-disable selector-class-pattern */
@import '../spacing-variables';

///

.Padding0 {
  padding: 0;
}

.XPadding0 {
  padding-left: 0;
  padding-right: 0;
}

.YPadding0 {
  padding-bottom: 0;
  padding-top: 0;
}

.TopPadding0 {
  padding-top: 0;
}

.RightPadding0 {
  padding-right: 0;
}

.BottomPadding0 {
  padding-bottom: 0;
}

.LeftPadding0 {
  padding-left: 0;
}

///

.PaddingXXL {
  padding: $spacing-xxl;
}

.XPaddingXXL {
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
}

.YPaddingXXL {
  padding-bottom: $spacing-xxl;
  padding-top: $spacing-xxl;
}

.TopPaddingXXL {
  padding-top: $spacing-xxl;
}

.RightPaddingXXL {
  padding-right: $spacing-xxl;
}

.BottomPaddingXXL {
  padding-bottom: $spacing-xxl;
}

.LeftPaddingXXL {
  padding-left: $spacing-xxl;
}

///

.PaddingXL {
  padding: $spacing-xl;
}

.XPaddingXL {
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
}

.YPaddingXL {
  padding-bottom: $spacing-xl;
  padding-top: $spacing-xl;
}

.TopPaddingXL {
  padding-top: $spacing-xl;
}

.RightPaddingXL {
  padding-right: $spacing-xl;
}

.BottomPaddingXL {
  padding-bottom: $spacing-xl;
}

.LeftPaddingXL {
  padding-left: $spacing-xl;
}

///

.PaddingL {
  padding: $spacing-l;
}

.XPaddingL {
  padding-left: $spacing-l;
  padding-right: $spacing-l;
}

.YPaddingL {
  padding-bottom: $spacing-l;
  padding-top: $spacing-l;
}

.TopPaddingL {
  padding-top: $spacing-l;
}

.RightPaddingL {
  padding-right: $spacing-l;
}

.BottomPaddingL {
  padding-bottom: $spacing-l;
}

.LeftPaddingL {
  padding-left: $spacing-l;
}

///

.PaddingM {
  padding: $spacing-m;
}

.XPaddingM {
  padding-left: $spacing-m;
  padding-right: $spacing-m;
}

.YPaddingM {
  padding-bottom: $spacing-m;
  padding-top: $spacing-m;
}

.TopPaddingM {
  padding-top: $spacing-m;
}

.RightPaddingM {
  padding-right: $spacing-m;
}

.BottomPaddingM {
  padding-bottom: $spacing-m;
}

.LeftPaddingM {
  padding-left: $spacing-m;
}

///

.PaddingS {
  padding: $spacing-s;
}

.XPaddingS {
  padding-left: $spacing-s;
  padding-right: $spacing-s;
}

.YPaddingS {
  padding-bottom: $spacing-s;
  padding-top: $spacing-s;
}

.TopPaddingS {
  padding-top: $spacing-s;
}

.RightPaddingS {
  padding-right: $spacing-s;
}

.BottomPaddingS {
  padding-bottom: $spacing-s;
}

.LeftPaddingS {
  padding-left: $spacing-s;
}

///

.PaddingXS {
  padding: $spacing-xs;
}

.XPaddingXS {
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
}

.YPaddingXS {
  padding-bottom: $spacing-xs;
  padding-top: $spacing-xs;
}

.TopPaddingXS {
  padding-top: $spacing-xs;
}

.RightPaddingXS {
  padding-right: $spacing-xs;
}

.BottomPaddingXS {
  padding-bottom: $spacing-xs;
}

.LeftPaddingXS {
  padding-left: $spacing-xs;
}
