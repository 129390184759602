@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ButtonContainer {
  @extend .TopMarginXXL;
  height: 100%;
  width: 100%;
}

.NextButton {
  float: right;
  height: 40px;
  min-width: 110px;
}

.ExtraButton {
  @extend .RightMarginM;
  height: 40px;
  min-width: 110px;
}

.HeaderContent {
  margin-bottom: 4px;
  text-align: end;

  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}
