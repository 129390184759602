@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.AlertContainer {
  width: 100%;
}

.LoadingModal,
%loadingmodal {
  @extend .TopMarginXL;
  background: #fafafa;
  border: 0;
  border-radius: 2px;
  margin-bottom: 24px;
  min-height: 40px;
  padding: 8px 16px;
  width: 100%;
}

.ApprovedModal {
  @extend %loadingmodal;
  background: #f6ffed;
}

.RejectedModal {
  @extend %loadingmodal;
  background: #fffbe6;
}

.SuccessIcon {
  color: $succcess-green;
}

.RejectedIcon {
  color: #faad14;
}

.AlertIcon {
  margin-right: 8px;
}

/* stylelint-disable */
:export {
  loaderColor: $primary;
  loaderColorOpacity: $primary-opacity
}
/* stylelint-enable */
