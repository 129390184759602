@import 'assets/styles/variables';
@import 'assets/styles/UtilityClasses/index';

.ContainerWithPaddingBotttom {
  padding-bottom: 24px;
}

.MainSelector {
  @extend .BottomMarginXXL;
}

.RadioButton,
%radiobutton {
  background: white;
  border: 1px solid $border-gray;
  border-radius: 5px;
  color: $font-dark;
  cursor: pointer;
  height: 40px;
}

.ActiveRadioButton {
  background: $pale-primary;
  border: 1px solid $primary;
}

.RadioCircleContainer {
  position: relative;
  right: 14px;
}

.RadioText {
  text-align: center;
  width: calc(100% - 22px);

  @media only screen and (max-width: $md) {
    padding-left: 12px;
  }
}

.Circle {
  border: 1px solid $border-gray;
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  width: 22px;
}

.ActiveCircle {
  background: $primary;
  border: 1px solid $primary;
}

.ActiveIcon {
  left: 3px;
  position: relative;
  top: -2px;
}

.DisabledCircle {
  background: white;
}

.InfoCircleTwoTone {
  font-size: 14px;
}

.PanelContent {
  width: 100%;
}

.PriceTitle {
  color: $font-secondary;
  margin-bottom: 12px;
}

.Price {
  margin-bottom: 0;
}

.PanelRowContainer {
  background: $pale-primary;
  border: solid 1px $primary;
  border-radius: 5px;
  padding: 16px;
}

.SkipButton {
  $text-collapse-breakpoint: 430px;
  background: white;
  font-size: 12px;
  margin-bottom: 0;
  text-align: right;
  width: 100%;
  @media only screen and (max-width: $text-collapse-breakpoint) { 
    top: -36px;
  }
}

.Popover {
  color: $primary;
  cursor: pointer;
  display: inline-block;
}
