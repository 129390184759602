.ModalTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 8px;
}

.Icon {
  color: #faad14;
  font-size: 24px;
}

.ModalText {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

.ButtonRow {
  margin-top: 24px;
}
