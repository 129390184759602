@import '../variables';

.FullScreen {
  height: 100vh;
  width: 100vw;
}

.FullSize {
  height: 100%;
  width: 100%;
}

.FullHeight {
  height: 100%;
}

.FullWidth {
  width: 100%;
}

.WhiteBackground {
  background: white;
}
