@import '~@cloudcar-app/cloudcar-components/styles/index';
@import './variables';
@import './fonts';
@import './UtilityClasses/index';

body {
  background-color: white;
  color: $font-dark;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 100vh;
  margin: 0;
  overflow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  color: $font-dark;
  font-size: 20px;
  font-weight: 500;

  @media only screen and (max-width: $lg) {
    font-size: 18px;
  }
}

h2 {
  color: $font-dark;
  font-size: 16px;
  font-weight: 500;
}

h3 {
  color: $font-dark;
  font-size: 14px;
  font-weight: 500;
}

h4 {
  color: $font-dark;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
}

p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

a {
  color: $font-primary;
  font-size: 12px;
  font-weight: 400;
}

input {
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

button {
  font-size: 16px;
  font-weight: 500;
}

hr {
  @extend .YMarginM;
  border: 1px solid $border-gray;
  border-radius: 5px;
}

// Ant Design Components
/* stylelint-disable */

#root {
  height: 100vh;
  overflow: none;
}

// Message
.ant-message-loading {
  svg {
    color: $primary;
  }
}

// Alert
.ant-alert-info {
  background: $gray-2;

  .ant-alert-icon {
    color: $primary;
  }
}

div[class*='ComponentContainer_'] {
  .ant-page-header {
    @extend .BottomPaddingXXL;
  }

  .ant-page-header-back {
    @extend .RightMarginXS;
  }
  .ant-page-header-heading-left {
    @extend .Margin0;
  }

  .ant-page-header-heading-title {
    font-size: 20px;

    @media only screen and (max-width: $lg) {
      font-size: 18px;
    }
  }

  .ant-page-header-heading-sub-title {
    width: auto;
    
    @media only screen and (max-width: $md) {
      width: 100%;
    }
  }
}

// Modal

.ant-modal:not(div[class*='Error']) {
  .ant-modal-close-x {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }

  svg {
    height: 14px;
    width: 14px;
  }
}

.ant-modal:not(div[class*='Error'], div[class*='Loading']) {
  .ant-modal-body {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    @extend .PaddingXXL;

    ul {
      @extend .BottomMargin0;
    }
  }
}

.ant-modal-wrap {
  overflow: hidden;
}

.CloudCarModal {
  max-height: calc(100vh - 270px);
  width: calc(100% + #{$scroll-bar-width});
}

// Loading Modal
div[class*='CreditLoadingModal'] {
  .ant-modal {
    max-width: 400px;
    width: 100%;
  }
  
  .ant-modal-content {
    height: 100%;
    min-height: 145px;
  }
  
  .ant-modal-body {
    @extend .PaddingXL;
  }

  .ant-progress-outer {
    @extend .RightPaddingXL;
  }

  .ant-progress-bg {
    background-color: #ffffff
  }
}

// Modal General Style
.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-header {
  border-radius: 5px 5px 0 0;
}

// TERMS & CONDITIONS
div[class*='TermsAndConditionsContent'] {
  padding: $spacing-xl;
  text-align: justify;

  h1 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 24px 0 16px 0
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0 16px 0
  }

  a {
    font-size: 14px;
  }

  hr {
    margin: 36px 0;
  }

  p {
    line-height: 24px;
  }
}

div[class*='TermsAndConditions'] {
  .ant-checkbox + span {
    padding-right: 0 !important;
  }
}

// Page Header

.ant-page-header-heading-left {
  align-items: baseline;

  @media only screen and (max-width: $sm) {
    flex-wrap: wrap !important;
  }
}

.ant-checkbox + span {
  margin-left: 4px;
}

span.ant-radio + * {
  padding-left: 12px;
}

// Ant TwoTone Icon

span[class*='InfoCircleTwoTone'] {
  path:nth-child(1) {
    fill: $primary;
  }

  path:nth-child(2) {
    fill: $pale-primary;
  }

  path:nth-child(3) {
    fill: $primary;
  }
}

button[class*='Button']:disabled span[class*='InfoCircleTwoTone'] {
  path:nth-child(1) {
    fill: $gray-text;
  }

  path:nth-child(2) {
    fill: $background-gray;
  }

  path:nth-child(3) {
    fill: $gray-text;
  }
}

/* Table */

div[class*='Table'] {
  color: $font-dark;
  font-size: 14px;
  font-weight: 400;

  .ant-table-wrapper {
    width: 100%;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: $primary;
  }

  .ant-table {
    border-radius: 5px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  .ant-table-container table > tbody > tr,  
  .ant-table-container table > tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
    padding: 12px 16px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

  .ant-table-container table > thead > tr > th {
    border-bottom: 1px solid $border-gray;
    padding: 15px 16px
  }

  .ant-table-container table > tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  .ant-table-container table > tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  .ant-table-container table > tbody > tr:last-child td {
    border-bottom: none;
  }

  .ant-table-container table > tbody > tr > td {
    border-bottom: 1px solid $border-gray;
  }
  
  .ant-table-cell {
    background: white;
    min-height: 56px;
  }

  thead {
    color: $font-dark;
    font-size: 16px;
    font-weight: 500;
  }

  tr {
    min-height: 56px;
    height: 56px;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: $pale-primary !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: $background-darker-gray;
  }

  p {
    margin-bottom: 0;
  }
}

div[class*='DisabledTable'] {
  .ant-table-cell {
    background: $background-gray;
    color: $font-secondary;
  }
}

/* stylelint-enable */

